<template>
  <div class="is-size-7">
    <p class="has-text-weight-semibold">
      Your password must contain:
    </p>
    <p>
      <span class="icon has-text-success">
        <i class="far fa-shield-check" />
      </span>
      <span>At least one digit</span>
    </p>
    <p>
      <span class="icon has-text-success">
        <i class="far fa-shield-check" />
      </span>
      <span>At least one upper case letter</span>
    </p>
    <p>
      <span class="icon has-text-success">
        <i class="far fa-shield-check" />
      </span>
      <span>At least one lower case letter</span>
    </p>
    <p>
      <span class="icon has-text-success">
        <i class="far fa-shield-check" />
      </span>
      <span>At least one special character</span>
    </p>
    <p>
      <span class="icon has-text-success">
        <i class="far fa-shield-check" />
      </span>
      <span>Minimum eight characters</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PasswordTooltip'
}
</script>
