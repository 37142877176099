<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-dark">Reset password</p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close">Cancel</a>
          </div>
        </div>
        <div class="content">
          <p>Providing you know your current password, you may update it</p>
        </div>
        <form class="columns is-multiline" @submit.prevent="save">
          <div class="column is-12">
            <label class="label has-text-grey-dark has-text-weight-normal"
              >Current password</label
            >
            <input
              v-validate.disable="{ required: true }"
              v-model="password.plaintext"
              type="password"
              name="current password"
              class="input"
              :class="{ 'is-danger': errors.has('current password') }"
            />
            <span v-if="errors.first('current password')" class="help">{{
              errors.first('current password')
            }}</span>
          </div>
          <div class="column is-6">
            <label class="label has-text-grey-dark has-text-weight-normal">
              <span>New password</span>
              <a
                v-tippy="{ placement: 'right', html: '#password-tooltip' }"
                class="icon is-small has-text-info"
              >
                <i class="fal fa-info-circle" />
              </a>
            </label>
            <password-tooltip id="password-tooltip" style="display: none" />
            <input
              v-validate.disable="{ required: true, min: 8, regex }"
              v-model="password.password"
              type="password"
              name="new password"
              class="input"
              :class="{ 'is-danger': errors.has('new password') }"
            />
            <span v-if="errors.first('new password')" class="help">
              {{ errors.first('new password') }}
            </span>
          </div>
          <div class="column is-6">
            <label class="label has-text-grey-dark has-text-weight-normal">
              New password confirmation
            </label>
            <input
              v-validate.disable="{
                required: true,
                confirmed: password.password
              }"
              v-model="passwordConfirmation"
              data-vv-as="password"
              type="password"
              name="password confirmation"
              class="input"
              :class="{ 'is-danger': errors.has('password confirmation') }"
            />
            <span v-if="errors.first('password confirmation')" class="help">
              {{ errors.first('password confirmation') }}
            </span>
          </div>
          <div class="column is-12">
            <button
              :disabled="$wait.is('save-password')"
              :class="{ 'is-loading': $wait.is('save-password') }"
              type="submit"
              class="button is-info"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePassword } from 'modules/account/services'
import PasswordTooltip from '../components/password/PasswordTooltip'
export default {
  name: 'AccountPassword',
  components: {
    PasswordTooltip
  },
  data: () => ({
    password: { plaintext: '', password: '' },
    passwordConfirmation: ''
  }),
  methods: {
    async save() {
      const valid = await this.$validator.validate()
      if (!valid) return

      try {
        this.$wait.start('save-password')
        await updatePassword({ request: this.password })
        this.$modal.close()
      } finally {
        this.$wait.end('save-password')
      }
    }
  },
  computed: {
    regex() {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]).{8,}$/g
    }
  }
}
</script>
